$primary: #ffcc33; /* MAIN COLOR */
$secondary: #00cccc; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #FFEAE5;

/* ======= GENERAL CSS =========== */

a {font-family: 'Croissant One', cursive;}

p, ol, ul {font-family: 'Lancelot', cursive;
	 	   font-size: 1.5em;}


ol, ul {font-family: 'Lancelot', cursive;
	 	   font-size: 1.25em;}


h1 {font-family: 'Croissant One', cursive;
    font-size: 4em;

		@media (max-width: 768px) {
			font-size: 2.5em;
		}
	
	}



h2 {font-family: 'Croissant One', cursive;
    font-size: 3em;}

h3 {font-family: 'Croissant One', cursive;
    font-size: 2em;}


.flash {
	display:none;
}

nav {
	z-index: 1100;
}


.row {margin-left: 0px;
	  margin-right: 0px;}


.container-fluid {padding-left: 0px;
				  padding-right: 0px;}

/* ========= NAVIGATION ========= */

.navbar-default {background: url('../img/navbar-header-bg.png');
				background-repeat: no-repeat;
				border-bottom: 5px double $secondary;
				box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                background-color: $wht;
				width: 100% !important;



}

.navbar-overlay {background-color: rgba(255, 255, 255, .5);
}


.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 15px;
		display: flex;
		align-items: center;
		color: $primary;
		text-shadow: 1px 1px $blk;
        font-weight: bold;
		outline: none;
		border-right: 1px solid $primary;


		@media (max-width: 767px) {
		    height: 30px;
			display: inline-block;
			border-right: 0px solid $secondary;
		}

	&:focus, &:visited {
	    	background: transparent;
	    	color: $primary;
}
	
		&:hover {
			color: $wht;
		}
	}
}


.navbar-right {
	margin-top: 45px;
	font-size: 2em;

	@media (max-width: 1024px) {
		font-size: 1.35em;
	}

	@media (max-width: 767px) {
		margin-top: 0px;
	}

}


.navbar-fixed-top {
	margin: 0px;
}
 
.navbar-toggle {
    margin: 25px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	background-color: $secondary !important;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}

.logo {max-width: 100%;
	   display: block;
	   margin: 0 auto;
	   max-width: 337px;
	   
	   
		@media (max-width: 767px) {
			max-width: 143px;
			margin-left: 2px;
		}		 
}

/* ========= BUTTONS ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited
{
    background-color: $primary;
    border-color: $wht;
	   color: $wht;
padding: 10px 20px;
    font-size: 19px;
    text-transform: uppercase;
    border-radius: 25px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
     -o-transition: all 1s;
	 box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.btn-download:hover {
    background-color: $secondary;
    color: $wht;

}

.btn-download {
    margin-bottom: 15px !important;
    display: block;
    margin: 0 auto;
    max-width: 175px;
	margin-top: 50px;


	@media (max-width: 767px) {

		margin-top: 10px;
	}



	@media (max-width: 1024px) {

		margin-top: 10px;
	}
}

.sticky-wrapper {height: auto !important;}

.top-pad {padding-top: 150px;}

/* ======= MODAL ======== */

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 5em auto;
	z-index: 1200;
}

.modal-open {padding: 0px !important;}

.modal-header, .modal-footer {
	background: $primary;
	color: #fff;
}

input#username {
	margin-bottom: 20px;
}

input#sumbit {width: 100px !important;

}

/* ============= SLIDER ============= */

.bannercontainer {
    width: 100%;
    position: relative;
    padding: 0px;
}



.bannercontainer .banner {
    width: 100%;
    position: relative;
	background-color: rgba(31, 31, 31, 0.75);
}

.banner {
    background: url(../img/banner.jpg) no-repeat;
    background-size: cover;
	background-position: 50% 50%;

	@media (max-width: 767px) {
		background-position: 50% 50%;
	}
}


/* ========== HEADER ============ */

.header {text-align: center;
		position: relative;
		margin-top: 115px;
		
		@media (max-width: 767px) {
			margin-top: 85px;
		}
		
		}

.header-overlay {
  background-color: rgba(31, 31, 31, 0.75);
}

.header h1 {	position: absolute;
				z-index: 1000;
				top: 150px;
				left: 20%;
				right: 20%;
				color: $primary;
				text-shadow: 2px 2px #00cccc;
				background-color: rgba(31, 31, 31, 0.5);
				border-radius: 15px;
				padding: 50px;
				box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

			@media (max-width: 1440px) {
				position: absolute;
				z-index: 1000;
				top: 125px;
				left: 5%;
				right: 5%;
				color: $primary;
				text-shadow: 2px 2px #00cccc;
				background-color: rgba(31, 31, 31, 0.5);
				border-radius: 15px;
				padding: 50px;
				box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
			}


			@media (max-width: 991px) {
				top: 10px;

			}


			@media (max-width: 768px) {
				display: none;
			}

}

  /* ========== MINIFORM AREA ============== */

.miniform {
background: rgb(252,234,187); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(252,234,187,1) 0%, rgba(252,205,77,1) 50%, rgba(251,223,147,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(252,234,187,1) 0%,rgba(252,205,77,1) 50%,rgba(251,223,147,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(252,234,187,1) 0%,rgba(252,205,77,1) 50%,rgba(251,223,147,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fceabb', endColorstr='#fbdf93',GradientType=0 ); /* IE6-9 */
	


	}

.miniform-overlay {
				  padding: 10px 0px 100px 0px;

		@media (max-width: 1199px) {
			padding: 0px;
		}


}

.miniform img {width: 100%;
			   display: block;
			   margin: 0 auto;
			   max-width: 650px;
			   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);


			@media (max-width: 1024px) {
				margin-top: 25px;
			}


			   }

.miniform h1 {
			  color: $wht;
			  text-shadow: 2px 2px $secondary;
			  padding: 10px 0px;

			  	@media (max-width: 1024px) {
					text-align: center !important;
				}


			}

.miniform h2 {color: $wht;
			 text-shadow: 2px 2px $secondary;
			 
			 
			 @media (max-width: 1024px) {
				 text-align: center;
				 font-size: 2em;
			 }
			 
			 
			 }

.miniform p {color: $blk;
			 letter-spacing: 1px;
			 line-height: 40px;
			 padding: 15px;
			 
			@media (max-width: 1199px) {
				font-size: 1.75em;
			}

			@media (max-width: 1024px) {
				text-align: center !important;	
			}

			@media (max-width: 767px) {
				text-align: center;
				font-size: 1.5em;
			}
}


/* ========== FOOTER ============ */

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $blk;
	font-size: .75em !important;

	a {
		color: $blk;

		&:hover {
			color:$secondary;
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
 }

/*=========== Upper CTA ============ */

.upper-cta {
background: rgb(0,204,204); /* Old browsers */
background: -moz-radial-gradient(center, ellipse cover,  rgba(0,204,204,1) 1%, rgba(249,249,249,1) 100%); /* FF3.6-15 */
background: -webkit-radial-gradient(center, ellipse cover,  rgba(0,204,204,1) 1%,rgba(249,249,249,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: radial-gradient(ellipse at center,  rgba(0,204,204,1) 1%,rgba(249,249,249,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00cccc', endColorstr='#f9f9f9',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

			@media (max-width: 1280px) {
				background-position: 50% 10%;
			}
			
			@media (max-width: 768px) {
				padding: 1px;
			}


}

.upper-cta-overlay {background: rgba(31, 31, 31, 0.19);
				  border-right: 2px solid $wht;
				  border-top: 2px solid $wht;
				  border-bottom: 2px solid $wht;

				  @media (max-width: 1199px) {
					margin: 0px;
					height: 293px;	  
				  }

				  @media (min-width: 992px) and (max-width: 1023px) {
					  margin: 0px !important;
					  height: 293px !important;
					  
				  }

				  @media (max-width: 1024px) {
					  margin: 5px;
					  border: 2px solid $wht;
					  height: auto;
				  }

}

.upper-cta-overlay:first-child {border-left: 2px solid $wht;}

.upper-cta h1 {
			   color: $wht;
			   text-shadow: 2px 2px $blk;
			
			@media (max-width: 767px) {
				font-size: 2.75em;
			}	
			   
			   
			   }

.upper-cta p {
			  color: $wht;
			  text-shadow: 1px 1px $blk;
			
			
			@media (max-width:1024px) {
				  font-size: 1.65em;
				  line-height: 40px;
			  }
			
			
			@media (max-width: 767px) {
				font-size: 1.5em;
			}	
}


/* ========== BENEFITS ============== */


.benefits {background: url('../img/lower-banner-2.jpg');
		   background-repeat: no-repeat;
		   background-position: 50% 10%;
		   background-size: cover;
		   -moz-background-size: cover;
		   -o-background-size: cover;
		   -webkit-background-size: cover;
		   text-align: center;
		   padding: 10px;

		   @media (max-width: 767px) {
			   background-position: 55% 10%;
		   }

}

.benefits-overlay {
  padding: 100px 0px;
  background-color: rgba(31, 31, 31, 0.5);

    @media (max-width: 1199px) {
	  padding-top: 80px;
  }


  @media (max-width: 1024px) {
	  padding: 0px;
	  text-align: center;
	   background-color: rgba(31, 31, 31, 0.75);
  }



}

.benefits h1 {
		      color: $wht;
			  text-shadow: 2px 2px $secondary;
	}

.benefits p {
			 letter-spacing: 1px;
			 line-height: 40px;
			 padding: 10px;
			 text-align: center;
			 color: $wht;
			 
			 
			 @media (max-width: 1024px) {
				color: $wht;
			 }
			 
			 
			 }


.benefits img {width: 100%;
			   max-width: 550px;
			   display: block;
			   margin: 0 auto;
			   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
			   margin-bottom: 25px;

			   @media (max-width: 1280px) {
				   display: none;
				}

			@media (max-width: 767px) {
				  max-width: 250px;
  			  }


}

.col-md-6 {width: 50%;

		 @media (max-width: 1440px) {
			 width: 100%;
			 padding: 5px;
		 }
}


/* ========== FEATURES ============== */


.features {padding: 50px 0px;
			@media (max-width: 1024px) {
				padding: 0px;
			}


}

.features h1 {
			  color: $secondary;
			  text-shadow: 2px 2px $primary;
			  text-align: center;

			  @media (max-width: 1024px) {
				  font-size: 3em;
			  }
}

.features h2 {
			  color: $secondary;
			  text-shadow: 2px 2px $primary;
			  
			  @media (max-width: 1024px) {
				  text-align: center;
			  }
			  @media (min-width:1024px) and (max-width: 1199px) {
				  font-size: 2em;
			  }
			  
			  }

.features p {
			 letter-spacing: 1px;
			 line-height: 30px;
			 color: $primary;
			 margin-bottom: 50px;
			 
			@media (max-width: 1024px) {
				  text-align: center;
			  }

			@media (min-width:1024px) and (max-width: 1199px) {
				  font-size: 1.35em;
			  }
}


.features div i {font-size: 200px;
				 color: $secondary;
				 text-shadow: 2px 2px $primary;}



.features img {width: 100%;
			   max-width: 250px;
			   display: block;
			   margin: 0 auto;


			   @media (max-width: 1024px) {
				   max-width: 250px;
			   }
		}


/*============ RECENT MEMBERS ============ */

.recent-members {background: url('../img/lower-banner.jpg');
				 background-position: 10% 10%;
				 		 background-size: cover;
 		 -moz-background-size: cover;
		 -o-background-size: cover;
  		 -webkit-background-size: cover;
		   padding: 10px;
				
				@media (max-width: 768px) {
					background-position: 100% 50%;
				}
				
				 }

.recent-members-overlay { padding: 50px 0px;
  background-color: rgba(31, 31, 31, 0.65);
  padding: 15px 0px;

  			@media (max-width: 767px) {
				  padding: 25px 0px;
			  }

}

.recent-members h1 {
			  color: $wht;
			  text-shadow: 2px 2px $secondary;
			  text-align: center;

			  @media (max-width: 1024px) {
				  font-size: 4em;
			  }

			  @media (max-width: 767px) {
				  font-size: 3em;
			  }
}

.recent-members h2 {
			  color: $wht;
			  text-shadow: 2px 2px $secondary;
			  
			  @media (max-width: 1024px) {
				  text-align: center;
				  padding-bottom: 25px;
			  }
			  @media (min-width:1024px) and (max-width: 1199px) {
				  font-size: 2em;
			  }
			  
			  }

.recent-members p {
			 letter-spacing: 1px;
			 line-height: 30px;
			 color: $wht;
margin-bottom: 50px;}

.recent-members img {
	width: 100%;
	max-width: 350px;
	display: block;
	margin: 0 auto;
	margin-bottom: 70px;
}
